type AuthStatus =
  | 'loading'
  | 'authenticated'
  | 'unauthenticated'

interface SignInOptions {
  email: string
  password: string
}

export function useAuth() {
  const me: Ref<any> = useState('auth:me', () => undefined)
  const loading: Ref<boolean> = useState(
    'auth:loading',
    () => false,
  )
  const baseApiURL = useRuntimeConfig().public.apiUrl

  const status = computed<AuthStatus>(() => {
    if (loading.value) {
      return 'loading'
    } else if (me.value) {
      return 'authenticated'
    } else {
      return 'unauthenticated'
    }
  })

  const fetchMe = async () => {
    loading.value = true

    console.log(baseApiURL);

    try {
      me.value = await $fetch(
        `${baseApiURL}${useRuntimeConfig().public.auth.endpoints.user}`,
        {
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
          onResponse({ response }) {
            if (response.status === 200) {
              response._data = response._data
            }
          },
        },
      )
    } catch (e) {
      me.value = null
    }

    loading.value = false
  }

  const signIn = async (options: SignInOptions) => {
    loading.value = true

    console.log(process.env.AUTEBA_API_URL);

    try {
      await $fetch(
        `${baseApiURL}${useRuntimeConfig().public.auth.endpoints.signIn}`,
        {
          method: 'post',
          body: {
            email: options.email,
            password: options.password,
          },
          credentials: 'include',
        },
      )

      fetchMe()
    } catch (e) {
      loading.value = false

      throw e
    }
  }

  return {
    me,
    fetchMe,
    loading,

    status,

    signIn,
  }
}
