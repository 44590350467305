import { default as _91id_93J7EjTe1IxEMeta } from "/opt/buildagent/work/6ac03ae5962f4eb7/src/pages/admin/company/[pageType]/[id].vue?macro=true";
import { default as dashboardRJJ5CnY7SrMeta } from "/opt/buildagent/work/6ac03ae5962f4eb7/src/pages/admin/dashboard.vue?macro=true";
import { default as indexfg9QNIaorWMeta } from "/opt/buildagent/work/6ac03ae5962f4eb7/src/pages/index.vue?macro=true";
import { default as loginNWyyUY1tzEMeta } from "/opt/buildagent/work/6ac03ae5962f4eb7/src/pages/login.vue?macro=true";
export default [
  {
    name: "admin-company-pageType-id",
    path: "/admin/company/:pageType()/:id()",
    meta: _91id_93J7EjTe1IxEMeta || {},
    component: () => import("/opt/buildagent/work/6ac03ae5962f4eb7/src/pages/admin/company/[pageType]/[id].vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    component: () => import("/opt/buildagent/work/6ac03ae5962f4eb7/src/pages/admin/dashboard.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexfg9QNIaorWMeta || {},
    component: () => import("/opt/buildagent/work/6ac03ae5962f4eb7/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginNWyyUY1tzEMeta || {},
    component: () => import("/opt/buildagent/work/6ac03ae5962f4eb7/src/pages/login.vue")
  }
]