export default {
  root: ({ props, context, parent }) => ({
    class: [
      'flex',
      'items-center',
      'content-between',
      'box-border',
      'bg-brand-blue',
      'justify-center',

      'py-3',
      'px-8',
      'gap-x-2',

      'h-10',

      'rounded-[10px]',
      'text-white',
    ],
  }),
}
