export default {
  root: {
    class: [
      'flex',
      'flex-row',
      'justify-between',
      'items-center',
      'overflow-auto',

      'rounded-md',
      'shadow-card',

      'bg-blueAdditional-blue-grey',
      'text-surface-700',
      'p-1',

      'border',
      'border-brand-blue',
    ],
  },
  listcontainer: {
    class: [
      'p-2',
      'gap-2',
      'shadow-lg',
      'bg-blueAdditional-blue-grey',
      'text-surface-700',
    ],
  },
  loader: {
    class: ['!hidden'],
  },
}
