export default {
  root: {
    class: [
      'flex',
      'items-center',
      'justify-center',

      'h-8',
      'p-2',

      'bg-blueAdditional-blue-grey',
      'rounded-lg',

      'border',
      'border-brand-blue',
    ],
  },
  listcontainer: {
    class: ['w-full', 'bg-blueAdditional-blue-grey'],
  },
}
